/* index.css */

/*app file */
body{
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 0;
}

#koalaTitle {
  padding-top: 10vh;
  height: 20vh;
  width: 100vw;
  background-color: #e4e4e4;
  position: relative;
  top: -1vh;
  left: -1vh;
}

#koalaArms { 
  left: 9.25vw;
  top: 27vh;
  position: absolute;
  z-index: 9;

}
#koalaBody{
  left: 7vw;
  position: absolute;
  z-index: 5;
}

#sproutLeft{
  position: relative;
  top: 23vh;
}
#sproutRight{
  position: relative;
  top: 2.25vh;
}
@keyframes sway {
  0% {
    transform: rotate(-1.5deg);
  }
  50% {
    transform: rotate(1.5deg);
  }
  100% {
    transform: rotate(-1.5deg);
  }
}

#sproutLeft {
  animation: sway 6s ease-in-out infinite; /* Adjust duration and timing function as needed */
}

#sproutRight {
  animation: sway 6s ease-in-out infinite; /* Adjust duration and timing function as needed */
}


#settingsButton {
  position: absolute;
  z-index: 7;
  background: transparent;
  border: none;
  padding: 0;
  right: 12vw;
  top: 15.5vh;
  animation: fadeIn 1.5s ease forwards;
}    
#buttonPng{
  position: absolute;
  z-index: 7;
  height: 6rem;
  animation: rotate 5s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


#koalaText {
  font-size: 8rem;
  color: #442519;
  font-family: "Lucida Console";
  position: absolute;
  left: 25vw;
  top: -4vh;
  animation: fadeIn 1.5s ease forwards;
}


#headBorder{
  border-bottom: #442519 solid 2px;
  position: relative;
  top: 19.8vh;
  width: 80.85vw;
  margin-left: 9.56vw;
  margin-right: 10vw;
  z-index: 3;
}

#settingsLabel{
  position: absolute;
  right: 4vw;
  top: 5vh;
  font-size: 2rem;
  font-family: "Lucida Console";
  width: 10.5vw;
  border-bottom: 2px solid black; /* Outer border */
  animation: fadeIn 1.5s ease forwards;
}
#borderDouble{
  position: absolute;
  right: 5vw;
  top: 13.25vh;
  width: 8vw;
  border-bottom: 2px solid black; /* Outer border */
  animation: fadeIn 1.5s ease forwards;
}

.sideborders-container {
  margin-top: -1vh;
  display: flex;
  height: 100vh; /* Adjust as needed */
}

/* Borders */
.border-left {
  width: 9vw;
  background-color: #e4e4e4;
  border-right: solid 2px #442519;
}

.border-right {
  width: 9vw; /* Adjust the width of the borders */
  background-color: #e4e4e4; /* Border color */
  border-left: solid 2px #442519;

}
/* Content area */
.content {
  flex: 1;
  background-color: #f0f0f0; /* Background color of the content area */
  display: flex;
  justify-content: center;
  overflow-y: auto;
  z-index: 7;
}

.content:focus{
  border: solid 6px #442519;
}

/*login file */

.loginContainer{
  width: 40vw;
  height: 85vh; 
  margin-top: 10vh;
  margin-bottom: 5vh;
  background-color: #ccc;
  position: relative;
  border: #442519 solid 2px;
  border-radius: .5rem;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.7);
  animation: fadeIn 4s ease forwards; /* Apply fadeIn animation */

}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start opacity from 0 */
  }
  to {
    opacity: 1; /* End opacity at 1 */
  }
}

#loginHeader{
  font-size: 2.5rem;
  font-family: "Lucida Console";
  margin-top: 10vh;
  color: #442519;
  text-align: center;
  position: relative;
  animation: fadeIn 4s ease forwards; /* Apply fadeIn animation */
  text-shadow: 2px 2px 0px #a5a5a5; /* Adjust the values to control the shadow */
  transform: perspective(100px) rotateX(10deg); /* Adjust the perspective and rotation */

}

.inputContainer {
  position: relative; /* Position the border relative to the container */

}
#positionBorder{
  display: flex;
  justify-content: center;
}
/* Add styles for the border element */
.inputBorder {
  position: absolute; /* Position the border absolutely */
  top: 19vh; /* Align the border to the bottom of the container */
  width: 10vw;
  height: 2px; /* Initial height */
  background-color: grey; /* Initial border color */
  transition: padding 0.3s, width 0.3s; /* Transition for border width */
  animation: fadeIn 4s ease forwards; /* Apply fadeIn animation */

}


.inputBorder.focused {
  width: 20vw; /* Change the width when focused */
  margin-top: .75vh;
}

/* Add styles for the input field */
.input-seed {
  height: 3rem;
  position: relative;
  top: 10vh;
  margin-left: 14vw;
  text-align: center;
  font-family: "Lucida Console";
  border: none;
  border-radius: .25rem;
  color: #27375c;
  animation: fadeIn 5s ease forwards; /* Apply fadeIn animation */
  overflow-x: auto;

}

.input-seed:hover, :focus{
  padding-top: 0.15rem; /* Increase padding on hover */
  top: 9.5vh;
  padding-left: .15rem;
  padding-bottom: .15rem;
  background-color: #9dafd7;
  border: solid #6c7a96 2px;
  box-shadow: 0 0 .5rem rgba(0, 0, 0, 0.7);
}
.input-seed::-webkit-inner-spin-button,
.input-seed::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.submitSeed {
  position: absolute;
  top: 40vh;
  height: 1.5rem;
  font-family: "Lucida Console";
  border-radius: .25rem;
  border: outset 2px #949393;
}
.submitSeed:hover{
  border: inset 3px #949393;
}
#centerSubmitButton{
  display: flex;
  justify-content: center;
}
#loadingSubmit { 
  top: 15vh;
  left: 6vw;
  position: relative;
  font-family: "Lucida Console";
}

#centerOr{
  display: flex;
  justify-content: center;
}
#optionOr{
  font-size: 3rem;
  margin-top: 23vh;
  color: #442519;
  font-family: "Lucida Console";
}
#newSeedContainer{
  position: relative;
  display: flex;
  justify-content: center;
}
#newSeed{
  position: absolute;
  border-radius: .25rem;
  font-family: "Lucida Console";
  border: 2px outset #949393; /* Set initial border */
  height: 2rem;
  top: 2vh;

}

#newSeed:hover {
  border: 3px inset #949393;
}


#loadingCreate {
  top: 5vh;
  left: 3vw;
  position: relative;
  font-family: "Lucida Console";
}
/* Styling for Main */

#mainContainer {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure the container fills its parent */
  max-width: 1200px; /* Maximum width of the white box, adjust as needed */
  height: 100%; /* Adjust as necessary */
  background-color: #b99e89; /* Assuming the white box has a white background */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
  padding: 20px;
  box-sizing: border-box; /* Include padding in the width */
  overflow-x: hidden;
}

#mainColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05); /* Light transparent background to visualize the column */
  margin: 20px; /* Space between columns */
}

#leftColumn {
  flex: 0 0 15%; /* Do not grow or shrink, set basis to 15% */
  margin-top: 25vh;
}

#headerContacts{
  color:#392113;
  margin-left: 5vw;
}

#listContacts{
  margin-left: 2vw;
  text-align: center;
}
#centerColumn {
  flex: 0 0 65%; /* Do not grow or shrink, set basis to 70% */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
#buttonOne{
  border: outset 3px #724330;
  background-color: #ccc;
  border-radius: .5rem;
  color: #392113;
  position: relative;
  margin-right: 2vw;
  height: 2rem;
  width: 8rem;
  top: -3vh;
  left: 10vw;
}
#buttonOne:hover{
  border: inset 3.5px #724330;
  width: 8.2rem;
  height: 2.2rem;
  top: -2.8vh;
  left: 9.8vw;
}
#buttonTwo{
  border: outset 3px #724330;
  background-color: #ccc;
  color: #392113;
  border-radius: .5rem;
  position: relative;
  margin-right: 2vw;
  height: 2rem;
  width: 8rem;
  top: -3vh;
  left: 12vw;
}
#buttonTwo:hover{
  border: inset 3.5px #724330;
  width: 8.2rem;
  height: 2.2rem;
  top: -2.8vh;
  left: 11.8vw;
}
#buttonThree{
  border: outset 3px #724330;
  background-color: #ccc;
  color: #392113;
  border-radius: .5rem;
  position: relative;
  height: 2rem;
  width: 8rem;
  top: -3vh;
  left: 14vw;
}
#buttonThree:hover{
  border: inset 3.5px #724330;
  width: 8.2rem;
  height: 2.2rem;
  top: -2.8vh;
  left: 13.8vw;
}

.loading-wheel {
  position: relative;
  left: 45vw;
  bottom: 4vh;
  width: 10px;
  height: 10px;
  border: 3px solid #ccc;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}


#koalaGraph img {
  position: absolute;
  top: 62vh;
  margin-left: 5vw;
  height: 50%;     /* Maintains the aspect ratio of the image */
  display: block;   /* Removes additional space below the image */
}

#rightColumn {
  flex: 0 0 20%; /* Do not grow or shrink, set basis to 15% */
  margin-top: 24.5vh;
  overflow-x: hidden;
  text-align: center;
  
}

#headerTransaction{
  left: 1vw;
  margin-right: 3vw;
  color: #392113;
}

.itemTransaction {
  position: absolute;
  margin-right: 12vw;
  width: 8rem;
  word-wrap: break-word; /* Add this line to enable word wrapping */

}
.header {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.list {
  list-style-type: none;
  padding: 0;
  width: 100%;

  color: #392113;
}

.listItem {
  border-bottom: 1px solid #392113;
  padding-bottom: 5px;
  margin-bottom: 5px;
}


.button {
  align-items: center;
  margin: 20px;
  margin-bottom: 10px;
  padding: 8px;
  cursor: pointer;
}

#loadingSend{
  position: relative;
  bottom: 3vh;
  left: 49vh;
}
.balance {
  position: relative;
  font-size: 3rem;
  top: 3vh;
  margin: 0;
  color: #442519;
  font-family: "Lucida Console";
}
#unitToken{
  position: relative;
  font-size: 2.5rem;
  left: 40vw;
  bottom: 6vh;
  color: #442519;
  font-family: "Lucida Console";
}

.graphPlaceholder {
  width: 100%;
  height: 150px; /* Set a fixed height for the placeholder */
  background-color: #ccc; /* Placeholder color */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #666;
  margin-top: 20px;
}

.modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #cbb4a1;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #392113;
  width: 80%;
  height: 20%;
  max-width: 500px; /* Could be any width */
  color: #3b2d25;
}
#labelRecipient {
  margin-right: 2vw;
}
#labelAmount{
  margin-right: 2vw;
}
#recipientPublicKey{
  border: outset #7e7065 2px;
  border-radius: .25rem;
  background-color: #9a948f;
}
#recipientPublicKey:hover{
 background-color: #c8bfb6;
}

#amountToSend{
  border: outset #7e7065 2px;
  border-radius: .25rem;
  background-color: #9a948f;
}
#amountToSend:hover{
 background-color: #c8bfb6;
}

#amountToSend::-webkit-inner-spin-button,
#amountToSend::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#buttonSendTransaction {
  border-radius: .25rem;
  border: outset 2px white;
  align-items: center;
  margin-top: 5vh;
  margin-left: 2vw;
  cursor: pointer;
  background-color: #9a948f;
}
#buttonSendTransaction:hover{
  background-color: #75716d;
  border: inset 2px white;
}



.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Settings.js */
#mainAccount{
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  z-index: 0;
}
#koalaOnTree{
  position: absolute;
  left: -1.5vw;
  top: 3vh;
  opacity: 0;
  animation: fadeIn 2s ease forwards; /* Apply fadeIn animation */
  z-index: 10;
}
#mainAccountInfo{
  position: relative;
  top: 2vh;
  font-size: 4rem;
  color: #724330;
  border-bottom: 2px solid #724330;;
  z-index: 4;
}
#alignPublicKey{
  display: flex;
  flex-direction: row;
  align-items: center;

}
#publicKeyText{
  position: relative;
  font-size: 2rem;
  color: #724330;
  top: 8vh;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 2s ease forwards; /* Apply fadeIn animation */
  animation-delay: .6s;
  z-index: 4;
}
#publicKey{
  position: relative;
  font-size: 2rem;
  top: 8vh;
  left: 3vw;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 2s ease forwards; /* Apply fadeIn animation */
  animation-delay: .6s;
  color: #724330;
  width: 50vw;
  word-wrap: break-word;
  z-index: 4;
}
#alignAddress{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#addressText{
  position: relative;
  font-size: 2rem;
  right: 2.75vw;
  top: 18vh;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 2s ease forwards; /* Apply fadeIn animation */
  animation-delay: 1s;
  color: #724330;
  z-index: 4;
}

#address{
  position: relative;
  font-size: 2rem;
  top: 18vh;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 3s ease forwards; /* Apply fadeIn animation */
  animation-delay: 1s;
  color: #724330;
  z-index: 4;
}
#address:empty{
  opacity: 0;
}
#alignSeedKey{
  display: flex;
  flex-direction: row;
  align-items: center;
}

#seedKeyText{
  position: relative;
  font-size: 2rem;
  right: 3.5vw;
  top: 30vh;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 3s ease forwards; /* Apply fadeIn animation */
  animation-delay: 1.5s;
  color: #724330;
  z-index: 4;
}

#seedKey{
  position: relative;
  font-size: 2rem;
  top: 30vh;
  margin: 0;
  opacity: 0; /* Initially hide the element */
  animation: fadeIn 3s ease forwards; /* Apply fadeIn animation */
  animation-delay: 1.5s;
  color: #724330;
  z-index: 4;
}
#seedKey:empty{
  opacity: 0;
}

#devLetter{
  position: relative;
  color: #3f9736;
  top: 40vh;
  font-size: 1.25rem;
  opacity: 0;
  animation: fadeIn 3s ease forwards; /* Apply fadeIn animation */
  animation-delay: 2s;
  font-family: "Lucida Console";
  z-index: 4;
  width: 80%;
}

#socials{
  font-family: "Lucida Console";
  color: #3f9736;
  animation: fadeIn 3s ease forwards; /* Apply fadeIn animation */
  animation-delay: 2s;
  z-index: 4;
}
@keyframes fadeIn {
  from {
    opacity: 0; /* Start opacity from 0 */
  }
  to {
    opacity: 1; /* End opacity at 1 */
  }
}